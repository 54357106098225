.page-header-admin {
  align-items: start;
  display: grid;
  grid-template-columns: calc(100% - #{$share-component-spacer}) $share-component-spacer;
  grid-template-rows: auto auto auto auto auto auto auto;
  margin-bottom: $base-component-mobile-whitespace;

  &:last-child {
    margin-bottom: 0;
  }

  .breadcrumbs+&:not(.page-header-admin--no-hero) {
    @media (min-width: $bp-min-small-tablet) {
      margin-top: rem(-32);
    }

    @media print {
      margin-top: 0 !important;
      /* stylelint-disable-line declaration-no-important */
    }
  }

  .rich-text {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  @media (min-width: $bp-min-small-tablet) {
    //background-color: pink;
    /*
     * OK, so what on earth is going on here?
     * Because of the way %s are calculated in CSS, and because the notch and hero need to span the full
     *   width of the page, we have to do some weird calculations to get the content of the page header
     *   to line up appropriately with the  1/3rd/2/3rds promos that may follow it down the page.
     * Most importantly, we need 33% and 67% of the content well, not of the container, here, hence the
     *   math
     * The calculations below give us:
     * margin | 33% of the content well | layout-columns spacer | 67% of the content well | margin
           calc(
        ((100% - #{$double-side-margin}) * 67 / 100) -
          (#{$layout-columns-spacer} / 2)
      )
     */
    grid-template-columns:
      $standard-side-margin 
      calc(((100% - $standard-side-margin*2) - (1.875rem * 11)) / 12 * 3 + (1.875rem * 2))
      calc(((100% - $standard-side-margin*2) - (1.875rem * 11)) / 12)
      calc(1.875rem * 2) 
      calc(100% - $standard-side-margin * 2 - calc(((100% - $standard-side-margin*2) - (1.875rem * 11)) / 12 * 3 + (1.875rem * 2)) - calc(((100% - $standard-side-margin*2) - (1.875rem * 11)) / 12) - calc(1.875rem * 2))
      $standard-side-margin;
    grid-template-rows:
      auto 
      60px 
      5.5625rem 
      5.5625rem 
      minmax(0, min-content) 
      5.5625rem 
      1fr;
    margin-bottom: $base-component-whitespace;

    &.page-header-admin--no-hero {
      // grid-template-rows: minmax(0, min-content) minmax(0, min-content) 1fr;
      grid-template-rows:
      2.5rem 5.5625rem minmax(0, min-content) minmax(0, min-content) 3rem minmax(0, min-content) minmax(0, min-content) 1fr;
      //2.5rem 5.5625rem 5.5625rem minmax(0, min-content) 3rem minmax(0, min-content) minmax(0, min-content) 1fr;
    }

    &.page-header-admin--no-notch {
      grid-template-columns: rem(126) 0 0 calc(100% - #{rem(252)}) rem(126);
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    display: grid;
//background-color: green;
    /*
     * Same basic idea as above, except we can use explicit widths for the columns since we know
     *   the width of the content well.
     */
    grid-template-columns: calc((100% - $max-width-rems) / 2)
     calc((($max-width - (1.875rem * 11)) / 12) * 3 + (1.875rem * 2))
      calc(((1.875rem * 2) + (($max-width - (1.875rem * 11)) / 12)) / 2) 
      calc(((1.875rem * 2) + (($max-width - (1.875rem * 11)) / 12)) / 2) 
      1fr 
      calc((100% - #{$max-width-rems}) / 2);

    &.page-header-admin--no-notch {
      grid-template-columns: calc((100% - #{$max-width}) / 2) 0 0 rem(930) calc((100% - #{rem(930)}) / 2);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
  }

  /* stylelint-enable declaration-no-important */
}

.page-header-admin__content-wrapper {
  margin: 0 $standard-side-margin;

  .page-header-admin__hero & {
    margin: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin: 0;
  }

  @media print {
    margin-left: 0;
    margin-right: 0;
  }
}

.page-header-admin__hero {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 1;
    grid-column-end: span 6;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}

.page-header-admin__crumb {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  padding: 3rem 0 0;
  height: 100%;
  justify-content: start;



  .page-header-admin__content-wrapper {
    margin-left: $standard-side-margin;

    @media (min-width: $bp-min-xxlarge) {
      margin-left: calc((100vw - 87.5rem) / 2);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    background-color: $drexel-grey-pale;
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 2;
    grid-row-end: span 4;
    position: relative;
    z-index: $stack-overlaps;
    justify-content: start;
    padding: 3rem 0 0;

    .page-header-admin--no-hero & {
      grid-row-start: 1;
      justify-content: center;
    }
  }

  @media print {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }

}

.page-header-admin__share {
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  padding: rem(42) 0 $standard-side-margin;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 5;
    grid-column-end: span 1;
    grid-row-start: 4;
    grid-row-end: span 1;
    padding-top: 0;

    .page-header-admin--no-hero & {
      grid-row-start: 2;
    }
  }

  @media print {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }
}

.page-header-admin__title {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 4;
  grid-row-end: span 1;
  display: inline-grid;

  h1 {
    max-width: none;

    @media (min-width: $bp-min-small-tablet) {
      max-width: 90%;
      grid-column-end: span 3;
      grid-row-start: 4;
    }
  }

  .page-header-admin__share+& {
    // make room for share button
    margin-right: $share-component-spacer;
  }

  .page-header-admin--no-notch .page-header-admin__share+& {
    // layout takes care of it, no need to make room
    margin-right: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 5;
    grid-column-end: span 1;
    grid-row-start: 4;
    grid-row-end: span 1;

    .page-header-admin--no-hero & {
      grid-row-start: 3;
    }
  }
}

.page-header-admin--no-hero .page-header-admin__breadcrumbs .breadcrumbs{
  //margin-top: rem(28);
}

.page-header-admin__breadcrumbs {
  grid-column-start: 2;
  grid-column-end: span 3;
  grid-row-start: 2;
  grid-row-end: span 1;
  padding: rem(42) 0 $standard-side-margin;

  

  .breadcrumbs {
    margin-left: 0;
    margin-top:0;
  }

  @media (min-width: $bp-min-small-tablet) {
    background-color: $white;
    grid-column-start: 3;
    grid-column-end: span 3;
    grid-row-start: 2;
    grid-row-end: span 1;
    position: relative;
    z-index: $stack-overlaps;
    padding-left: calc($layout-columns-spacer * 2);

    .page-header-admin--no-hero & {
      grid-row-start: 2;
      justify-content: start;
      padding-top: 0;
    }

    .breadcrumbs {
      margin-left: 0;
    }
  }

  @media print {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }

}

.page-header-admin__subnav {

  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 3;
  grid-row-end: span 1;
  // padding-top: rem(15);
  // padding-bottom: rem(15);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 3.75rem;
  }

  .page-header-admin__content-wrapper {
    margin-left: $standard-side-margin;
    margin-right: $standard-side-margin;

    @media (min-width: $bp-min-small-tablet) {
      margin-bottom: $base-component-whitespace;
    }

    @media (min-width: $bp-min-xxlarge) {
      margin-left: calc(((100vw - $max-width) / 2));
    }

  }

  @media (min-width: $bp-min-small-tablet) {
    background-color: $drexel-grey-pale;
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 4;
    grid-row-end: span 4;
    position: relative;
    z-index: $stack-overlaps;

    .page-header-admin--no-hero &,
    .page-header-admin--no-hero.page-header-admin--sidebar & {
      grid-row-start: 2;
      grid-row-end: span 2;
    }

    .page-header-admin--no-title &,
    .page-header-admin--no-title.page-header-admin--sidebar & {
      grid-row-end: span 4;
    }

    .page-header-admin--sidebar & {
      grid-row-end: span 3;
    }
  }

  @media print {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }
}

.page-header-admin__body {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 6;
  margin-bottom: $base-component-mobile-whitespace;

  .intro-paragraph {
    max-width: 90%;
  }

  /* Convoluted, but if the share is immediately before the subnav (no title), account for it */
  .page-header-admin__share+.page-header-admin__subnav+& {
    @media (min-width: $bp-min-small-tablet) {
      margin-right: $share-component-spacer;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 5;
    grid-column-end: span 1;
    grid-row-start: 4;
    grid-row-end: span 5;
    margin-bottom: 0;
    align-self: start;

    .page-header-admin__share+.page-header-admin__subnav+& {
      margin-right: 0;
    }

    .page-header-admin--no-hero &,
    .page-header-admin--no-hero.page-header-admin--sidebar & {
      grid-row-start: 4;
      grid-row-end: span 5;
    }

    .page-header-admin--sidebar & {
      grid-row-start: 4;
      grid-row-end: span 4;
    }

    .page-header-admin--no-title & {
      grid-row-start: 4;
      grid-row-end: span 3;
    }
  }

  // When no title (ex. Admissions), make space for the share
  .page-header-admin--no-title & {
    @include spaceForShare($bp-min-large, rem(1557));
  }
}

.page-header-admin__element--show-tablet {
  display: none;

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }
}

.page-header-admin__element--hide-tablet {
  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }
}

.page-header-admin__sidebar {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 7;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 7;
    grid-row-end: span 1;

    .page-header-admin--no-hero & {
      grid-row-start: 6;

    }
  }

  @media print {
    display: none;
  }
}

.page-header-admin__share .page-header-admin__content-wrapper .share {
  position: relative;
}

.bleed-hero--administrative .bleed-hero__body {
  left: 0;

  @media (min-width: $bp-min-small) {
    left: $standard-side-margin;
    padding-bottom: 5.75rem;
  }

  @media (min-width: $bp-min-xxlarge) {
    left: calc((100vw - 87.5rem) / 2);
  }
}

section.page-header-admin.page-header-admin--no-hero{

  .page-header-admin__crumb {
    grid-row-start: 2;
    grid-row-end: span 4;
    justify-content: start;
    padding: 1rem 0 0;

    @media (min-width: $bp-min-small-tablet) {
      justify-content: start;
      grid-row-start: 1;
      padding: 3rem 0 0;
    }

  }

  .page-header-admin__share {
    grid-row-start: 1;

    @media (min-width: $bp-min-small-tablet) {
      grid-row-start: 2;
    }
  }

  .page-header-admin__subnav {
    grid-row-start: 3;

    @media (min-width: $bp-min-small-tablet) {
      grid-row-start: 2;
    }

  }

  .page-header-admin__title {
    grid-row-start: 1;
    padding-top: 2.875rem;

    @media (min-width: $bp-min-small-tablet) {
      grid-row-start: 3;
      padding-top: initial;
    }
  }

}