.search-flyout {
  .search-box input {
    border: 1px solid $drexel-grey-soft;
  }
}

.search-flyout__search-box.is-open .search-box__button {
  border: 1px solid $drexel-grey-soft;
  border-left: none;
}
