.header--office {
  background: transparent;
  border-bottom: 1px solid rgba($drexel-grey-text, 0.2);

  @media (min-width: $bp-min-small-tablet) {
    border-bottom: none;
  }
}

// Only used in the office header currently
.header__left {
  background-color: transparent;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 98, 152, 1) 0%,
    rgba(7, 41, 77, 1) 100%
  );
  color: $white;
}

// Only used in the office header currently
.header__right {
  a,
  button {
    color: $drexel-navy;

    &:hover,
    &:focus {
      color: $drexel-blue;
    }
  }
}

.header__primary-navigation {
  @media (min-width: $bp-min-small-tablet) {
    border-top: 1px solid rgba($drexel-grey-text, 0.2);
  }
}
